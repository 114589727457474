@font-face {
  font-family: 'Blue River';
  src: url('./utils/fonts/blue_river/BlueRiverRegular.woff2') format('woff2'),
      url('./utils/fonts/blue_river/BlueRiverRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Santor';
  src: url('./utils/fonts/santor/Santor.woff2') format('woff2'),
      url('./utils/fonts/santor/Santor.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CAROUSEL */
.carouselImage {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
  object-position: 50% 10%;
}

.carousel {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 69vh;
  max-width: 100%;
}

/* TITLE */

#title {
  font-family: 'Blue River', sans-serif;
  font-size: 8rem;
  font-weight: normal;
  margin: 10px;
  line-height: 1.9;
}

p {
  font-family: 'Santor', sans-serif;
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;
}

.textContainer {
  margin-top: 10px;
}

.categoryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.categoryImage {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: 50% 10%;
}

.categoryCard {
  width: 22rem;
  margin-top: 10px;
}

.articleCard {
  width: 60rem;
  max-width: 600px;
  margin-top: 10px;
  height: fit-content
}

.mediaLink {
  width: 69vh;
  height: 40vh;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  border: none;
}

.contentDescription {
  margin-left: 10vh;
  margin-right: 10vh;
  margin-top: 10px;
}

.shareButton {
  cursor: pointer;
  transition: 0.3s linear;
}

.shareButton:active {
  transform: scale(0.85);
}

.cardTitle {
  font-size: 1.7em;
}

.cardTitleBox {
  text-align: center;
}

.popup {
  position: absolute;
  left: 25%;
  right: 25%;
  background-color: #161bcf;
  border-radius: .4em;
  padding: 0.4em;
  animation: slideIn 0.5s ease forwards;
  opacity: 0.7;
  top: -5vh;
}

@keyframes slideIn {
  0%{}
  70%{transform: translateY(-30%)}
  100%{transform: translateY(-27%)}
}

/* PHONE VIEW */

@media (max-width: 500px) {

  html, body {
    /* width: fit-content; */
    flex-direction: column;
  }

  #title {
    font-size: 3.5rem;
    line-height: 1.5;
    margin-bottom: -15px;
    text-align: center;
  }

  p {
      font-size: 1.1rem;
  }

  .articleCard {
    width: 100%;
    height: fit-content;
    border: blue;
    margin-left: 25px;
    margin-right: 25px;
  }

  .categoryContainer {
    display: block;
  }

  .categoryCard {
    width: 100%;
  }

  .categoryImage {
    width: 100%;
    heigth: 20rem;
    object-fit: cover;
  }

  .carouselImage {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 10%;
  }

  .carousel {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .mediaLink {
    width: 100%;
    height: 40vh;
  }

  .contentDescription {
    margin-left: 0;
    margin-right: 0;
  }

  .carouselImage {
    width: 100%;
    aspect-ratio: 1/1;
  }

  .carousel {
    width: 100%;
  }
}